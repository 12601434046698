import { toast } from 'vue3-toastify';
import { registerSW } from 'virtual:pwa-register';

export const install = () => {
  const updateSW = registerSW({
    onNeedRefresh() {
      toast.info('Pembaruan tersedia. Muat ulang halaman?', {
        autoClose: false,
        closeOnClick: true,
        onClick: () => updateSW(true)
      });
    },
    onOfflineReady() {
      toast.success('Aplikasi siap digunakan offline.');
    }
  });
};
