<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { registerSW } from 'virtual:pwa-register';

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();
registerSW({ immediate: true });

function close() {
  offlineReady.value = false;
  needRefresh.value = false;
}
</script>

<template>
  <div v-if="offlineReady || needRefresh" class="pwa-toast" role="alert">
    <div class="message">
      <span v-if="offlineReady">New content available, click on reload button to update.</span>
      <span v-else>New content available, click on reload button to update.</span>
    </div>
    <button v-if="needRefresh" @click="updateServiceWorker()" type="button">Reload</button>
    <button @click="close" type="button" class="btn btn-sm btn-succss">Update</button>
  </div>
</template>

<style>
.pwa-toast {
  position: fixed;
  right: 20px;
  bottom: 20px;
  margin: 0;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1000; /* Ensure it's on top of other elements */
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: white;
}

.pwa-toast .message {
  margin-bottom: 8px;
}

.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
  cursor: pointer; /* Ensures the button looks clickable */
  background-color: #f0f0f0;
}

.pwa-toast button:hover {
  background-color: #e0e0e0;
}
</style>
